import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';

import { Groups } from '../Groups';

import { classes, vars } from './Sidebar.st.css';

export function Sidebar() {
  const { isSSR, isEditor } = useEnvironment();

  const container$ = React.useRef<HTMLDivElement>(null);
  const [hasAds, setHasAds] = React.useState(false);

  React.useEffect(() => {
    if (isSSR || isEditor) {
      return;
    }

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    function handleScroll() {
      const clientRect = container$.current?.getBoundingClientRect();

      container$.current?.style.setProperty(
        vars.offsetTop,
        `${clientRect?.top || 0}px`,
      );
    }
  }, []);

  React.useEffect(() => {
    if (isSSR) {
      return;
    }

    if (document.getElementById('WIX_ADS')) {
      setHasAds(true);
    }
  }, [isSSR]);

  return (
    <div
      ref={container$}
      className={cls(classes.root, {
        [classes.hasAds]: hasAds,
        [classes.editor]: isEditor,
      })}
    >
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Groups />
        </CardContent>
      </Card>
    </div>
  );
}

Sidebar.displayName = 'Sidebar';
